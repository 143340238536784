const SolidLine = ({ isDark }) => {
	const style = {
		border: "none",
		borderTop: `1.5px solid ${isDark ? "#8f3a59" : "#ddc8d0"}`,
		width: "100%",
		marginTop: "1rem",
		marginBottom: "0.25rem",
	};

	return <hr style={style} />;
};

export default SolidLine;

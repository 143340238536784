import { useState, useEffect } from "react";

export const useSpeechSynthesisRate = () => {
	const [rate, setRate] = useState(null);

	useEffect(() => {
		const handleVoicesChanged = () => {
			const savedVoiceRate = localStorage.getItem("selectedRate") || 1;
			setRate(savedVoiceRate);
		};

		// Call the handler manually in case the voices have already been loaded
		handleVoicesChanged();
	});

	return { rate, setRate };
};

import { useEffect, useState } from "react";

import { updateVersion } from "../../../services/flightService";
import { useFlightContext } from "../../../contexts/FlightContext";
import { TextToSpeech, SmallLoader } from "../../../components";
import { askGPT } from "../../../services/flightService";

import "./ideation.css";

export default function Ideation() {
	const { currentVersion, setCurrentVersion } = useFlightContext();

	const [isLoading, setIsLoading] = useState(false);

	const { userChallengeAbstract: brainstormingQuestion, inspirationTexts: inspirations } = currentVersion;
	const inspirationsArray = Object.values(inspirations);

	const [selectedIdea, setSelectedIdea] = useState(currentVersion.selectedIdea || "");
	const [ideas, setIdeas] = useState(currentVersion.ideas ? currentVersion.ideas.split("\n") : []);
	const personaName = currentVersion.personas[currentVersion.selectedPersona]?.name;
	const personaNeed = currentVersion.personas[currentVersion.selectedPersona]?.need;
	const personaInsight = currentVersion.personas[currentVersion.selectedPersona]?.insight;

	const handleClick = async (idea) => {
		setSelectedIdea(idea);

		const updatedVersion = await updateVersion(currentVersion._id, {
			versionNumber: currentVersion.versionNumber,
			selectedIdea: idea,
			conceptPoster: "",
			conceptPosterImageURL: "",
		});

		setCurrentVersion(updatedVersion);
	};

	useEffect(() => {
		const fetchData = async () => {
			console.log("Fetching ideas...");

			setIsLoading(true);

			if (!currentVersion.ideas || currentVersion.ideas.trim() === "") {
				const response = await askGPT(
					[
						{
							role: "user",
							content: `Persona Need: ${personaNeed}, Persona Insight: ${personaInsight}`,
						},
					],
					`Generate 20 solution ideas based on the inspirations in ${inspirationsArray}, applied to the question ${brainstormingQuestion}. \
					Focus on desirability from the perspective of ${personaName}—technical feasibility is secondary. \
					Return a list of 20 ideas as headlines only, with no additional text.`
				);

				const updatedVersion = await updateVersion(currentVersion._id, {
					versionNumber: currentVersion.versionNumber,
					ideas: response,
				});

				setCurrentVersion(updatedVersion);
				setIdeas(response.split("\n\n"));
			}

			setIsLoading(false);
		};

		if (!currentVersion.ideas) {
			fetchData();
		}
	}, [currentVersion.versionNumber]);

	useEffect(() => {
		setSelectedIdea(currentVersion.selectedIdea);
		setIdeas(currentVersion.ideas ? currentVersion.ideas.split("\n") : []);
	}, [currentVersion]);

	return (
		<div id="ideation" className="tab-container">
			<h2>Ideation</h2>

			{isLoading ? (
				<SmallLoader />
			) : (
				<>
					{ideas &&
						ideas.map((idea, index) => (
							<div key={index} style={{ display: "flex", gap: "1rem", alignItems: "center", margin: "0" }}>
								<div
									style={{ width: "100%" }}
									className={`ideation-item ${selectedIdea === idea ? "selected-ideation-item" : ""}`}
									onClick={() => handleClick(idea)}>
									<p>{idea}</p>
								</div>

								<TextToSpeech id={idea} text={idea} />
							</div>
						))}
				</>
			)}
		</div>
	);
}

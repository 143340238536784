import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { BounceLoaderPlum, ShowPasswordButton } from "../../components";
import { login } from "../../services/authService";
import { createCard } from "../../services/feedbackService";
import { useAuthContext } from "../../contexts/AuthContext";
import useLocalState from "../../utils/localState";

import LogoPlum from "../../assets/logo-plum.png";
import "./auth.css";

export default function Login() {
	const { saveUser } = useAuthContext();
	const navigate = useNavigate();

	const { alert, showAlert, loading, setLoading, hideAlert } = useLocalState();

	const [showPassword, setShowPassword] = useState(false);
	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});

	const changeHandler = (e) => {
		setFormData((oldData) => ({
			...oldData,
			[e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
		}));
	};

	const submitHandler = async (e) => {
		e.preventDefault();

		if (!formData.email || !formData.password) {
			showAlert({ text: "All fields are required" });
			return;
		}

		hideAlert();
		setLoading(true);

		try {
			const response = await login(formData.email, formData.password);

			setFormData({ email: "", password: "" });

			if (response.status === 200) {
				const user = await response.json();
				saveUser(user.user);

				await createCard("NEW LOGIN", `${user.user.email} logged in - ${new Date().toLocaleString()}`);

				navigate("/");
			} else {
				const message = await response.json();
				showAlert({ text: message.msg });
			}
		} catch (error) {
			showAlert({ text: "Something went wrong. Try again later" });
		} finally {
			setLoading(false);
		}
	};

	const handleShowPassword = (e) => {
		setShowPassword(!showPassword);
	};

	return (
		<>
			<BounceLoaderPlum isLoading={loading} />

			{alert.show && <div className="message-container">{alert.text}</div>}

			<div id="login">
				<div className="auth-left">
					<a href="/">
						<div className="auth-logo">
							<img src={LogoPlum} alt="auth-logo" />
						</div>
						<h2 className="auth-title">Innovation Flightdeck</h2>
					</a>
				</div>

				<div className="auth-right">
					<div className="auth-form-container">
						<h1 className="text-plum">Log in</h1>
						<h2>to your account</h2>
						<div className="separator"></div>

						<p>
							You haven't sighned up yet?{" "}
							<b>
								<span className="text-plum">
									<Link to="/auth/register" className="link">
										Create an account!
									</Link>
								</span>
							</b>
						</p>

						<form className="form" onSubmit={submitHandler}>
							<div>
								<label htmlFor="email">
									<b>Email</b>
								</label>
								<input
									id="email"
									type="email"
									placeholder="johndoe@mail.com"
									name="email"
									value={formData.email}
									onChange={changeHandler}
									className="auth-input"
									required
								/>
							</div>
							<div className="relative">
								<label htmlFor="password">
									<b>Password</b>
								</label>
								<input
									id="password"
									type={showPassword ? "text" : "password"}
									placeholder="* * * * * *"
									name="password"
									value={formData.password}
									onChange={changeHandler}
									className="auth-input"
									required
								/>
								<ShowPasswordButton showPassword={showPassword} onClick={handleShowPassword} />
							</div>
							<button className="auth-btn" type="submit">
								Log in
							</button>

							<p>
								Forgot your password?{" "}
								<b>
									<span className="text-plum">
										<Link to="/auth/forgot-password" className="link">
											Reset password
										</Link>
									</span>
								</b>
							</p>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Error, NotFound } from "../index";
import { Collapsible } from "../../components";
import { getShareData, getVersionById } from "../../services/flightService";
import { createMarkup } from "../../utils/helpers";

import "./share.css";
import { SCENARIO_TITLES } from "../../assets/feedData";

export default function Share() {
	const { id } = useParams();

	const [flight, setFlight] = useState(null);
	const [version, setVersion] = useState(null);
	const [error, setError] = useState(null);

	const handleVersionChange = async (e) => {
		const selectedVersionId = e.target.value;

		try {
			const newVersion = await getVersionById(selectedVersionId);
			setVersion(newVersion);
		} catch (err) {
			setError({ status: 500, message: "An error occurred while fetching the version." });
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getShareData(id);

				if (response.error) {
					return setError(response);
				}

				setFlight(response);

				const currentVersion = await getVersionById(response.versions[response.versions.length - 1]);
				setVersion(currentVersion);
			} catch (err) {
				setError({ status: 500, message: "An error occurred while fetching data." });
			}
		};

		fetchData();
	}, [id]);

	const selectedPersonaContent =
		version?.selectedPersona &&
		Object.entries(version.personas[version.selectedPersona]).reduce((acc, [key, value], index) => {
			if (key === "touchpoints") {
				return acc;
			}
			if (key === "url") {
				acc.unshift(<img key={key} src={`data:image/png;base64,${value}`} className="card-img" style={{ marginBottom: "1rem" }} alt="img" />);
				return acc;
			}
			acc.push(
				<div key={key} style={{ marginBottom: "1rem" }}>
					<span>{key.charAt(0).toUpperCase() + key.slice(1)}</span>: {value}
				</div>
			);
			return acc;
		}, []);

	const selectedPersonaTouchpoints =
		version?.selectedPersona &&
		Object.entries(version.personas[version.selectedPersona].touchpoints).map(([key, touchpoint], index) => {
			return (
				<div key={index} style={{ marginBottom: "1rem" }}>
					<div>
						Touchpoint {index + 1}: {touchpoint.title}
					</div>
					<div>Actions: {touchpoint.actions}</div>
					<div>Thoughts: {touchpoint.thoughts}</div>
					<div>Satisfaction Level: {touchpoint.satisfactionLevel}</div>
				</div>
			);
		});

	const selectedTouchpoint = version?.selectedTouchpoint && (
		<div>
			<p>{`Title: ${Object.values(version.selectedTouchpoint)[1].title}`}</p>
			<p>{`Thoughts: ${Object.values(version.selectedTouchpoint)[1].thoughts}`}</p>
			<p>{`Actions: ${Object.values(version.selectedTouchpoint)[1].actions}`}</p>
			<p>{`Satisfaction Level: ${Object.values(version.selectedTouchpoint)[1].satisfactionLevel}`}</p>
		</div>
	);

	const firstScenarioContent = version?.inspirationTexts && version?.inspirationImages && (
		<div>
			<img src={`data:image/png;base64,${version.inspirationImages["FIRST_SCENARIO"]}`} alt="img" className="card-img" />
			<div>{version?.inspirationTexts["FIRST_SCENARIO"]}</div>
		</div>
	);

	const secondScenarioContent = version?.inspirationTexts && version?.inspirationImages && (
		<div>
			<img src={`data:image/png;base64,${version.inspirationImages["SECOND_SCENARIO"]}`} alt="img" className="card-img" />
			<div>{version?.inspirationTexts["SECOND_SCENARIO"]}</div>
		</div>
	);

	const thirdScenarioContent = version?.inspirationTexts && version?.inspirationImages && (
		<div>
			<img src={`data:image/png;base64,${version.inspirationImages["THIRD_SCENARIO"]}`} alt="img" className="card-img" />
			<div>{version?.inspirationTexts["THIRD_SCENARIO"]}</div>
		</div>
	);

	const fourthScenarioContent = version?.inspirationTexts && version?.inspirationImages && (
		<div>
			<img src={`data:image/png;base64,${version.inspirationImages["FOURTH_SCENARIO"]}`} alt="img" className="card-img" />
			<div>{version?.inspirationTexts["FOURTH_SCENARIO"]}</div>
		</div>
	);

	const conceptPosterContent = version?.conceptPoster && version?.conceptPosterImageURL && (
		<div>
			<img src={`data:image/png;base64,${version.conceptPosterImageURL}`} alt="img" className="card-img" />
			<div>{version?.conceptPoster}</div>
		</div>
	);

	if (error) {
		if (error.status === 404) {
			return <NotFound />;
		} else {
			return <Error error={error} />;
		}
	}

	console.log(version);

	return (
		<div className="share-container">
			{version?.designChallenge && <h2 dangerouslySetInnerHTML={createMarkup(version.designChallenge)} />}

			<select onChange={handleVersionChange} value={version?._id || ""}>
				{flight?.versions.map((v, index) => (
					<option key={v} value={v}>
						{`Version ${index + 1}`}
					</option>
				))}
			</select>

			<p style={{ textAlign: "right", marginTop: "0.5rem" }}>{version?.createdAt && new Date(version.createdAt).toLocaleString()}</p>

			<div className="divider"></div>

			{version?.summary && <Collapsible title={"Summary"} content={version.summary} />}

			{version?.designChallenge && <Collapsible title={"Design Challenge"} content={version.designChallenge} />}

			{version?.mainProblems && <Collapsible title={"Main Problems"} content={version.mainProblems} />}

			{version?.goodPractices && <Collapsible title={"Good Practices"} content={version?.goodPractices} />}

			{version?.selectedPersona && <Collapsible title={"Selected Persona"} content={selectedPersonaContent} />}

			{version?.selectedPersona && <Collapsible title={"User Journey"} content={selectedPersonaTouchpoints} />}

			{version?.selectedTouchpoint && <Collapsible title={"Selected Touchpoint"} content={selectedTouchpoint} />}

			{version?.jobToBeDone && <Collapsible title={"Job to be Done"} content={version?.jobToBeDone} />}

			{version?.painsAndGains && <Collapsible title={"Pains and Gains"} content={version?.painsAndGains} />}

			{version?.userChallengeAbstract && <Collapsible title={"Selected Challenge"} content={version?.userChallengeAbstract} />}

			{version?.inspirationTexts && version?.inspirationImages && (
				<Collapsible title={`Inspiration - ${SCENARIO_TITLES.FIRST_SCENARIO}`} content={firstScenarioContent} />
			)}

			{version?.inspirationTexts && version?.inspirationImages && (
				<Collapsible title={`Inspiration - ${SCENARIO_TITLES.SECOND_SCENARIO}`} content={secondScenarioContent} />
			)}

			{version?.inspirationTexts && version?.inspirationImages && (
				<Collapsible title={`Inspiration - ${SCENARIO_TITLES.THIRD_SCENARIO}`} content={thirdScenarioContent} />
			)}

			{version?.inspirationTexts && version?.inspirationImages && (
				<Collapsible title={`Inspiration - ${SCENARIO_TITLES.FOURTH_SCENARIO}`} content={fourthScenarioContent} />
			)}

			{version?.selectedIdea && <Collapsible title={"Selected Idea"} content={version?.selectedIdea} />}

			{version?.conceptPoster && version.conceptPosterImageURL && <Collapsible title={"Concept Poster"} content={conceptPosterContent} />}

			<div className="divider"></div>
		</div>
	);
}

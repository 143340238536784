import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

import { updateVersion } from "../../../services/flightService";
import { useFlightContext } from "../../../contexts/FlightContext";

import UserImg from "../../../assets/user.png";
import "./user-journey.css";

export default function UserJourney() {
	const { isSidebarVisible, currentVersion, setHasSubmittedJobToBeDone, setHasSubmittedPainsAndGains, setCurrentVersion } = useFlightContext();

	const [selectedPersona, setSelectedPersona] = useState(currentVersion.selectedPersona);
	const [persona, setPersona] = useState(currentVersion.personas[selectedPersona]);
	const [selectedTouchpoint, setSelectedTouchpoint] = useState(currentVersion.selectedTouchpoint);
	const [userJourney, setUserJourney] = useState(currentVersion.personas[selectedPersona].touchpoints);

	window.addEventListener("error", (event) => {
		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		if (event.message.includes("evaluating 'a.gridRect.getBoundingClientRect" && isSafari)) {
			console.log("Caught and ignored the specific error");

			event.preventDefault();
			event.stopPropagation();

			return true;
		}
	});

	async function handleChartEvent(event, chartContext, config) {
		const clickedTouchpointIndex = config.dataPointIndex + 1;
		const touchpointKey = `touchpoint${clickedTouchpointIndex}`;
		const clickedTouchpoint = userJourney[touchpointKey];

		if (clickedTouchpoint?.title !== currentVersion.selectedTouchpoint?.[currentVersion.selectedPersona]?.title) {
			const updatedVersion = await updateVersion(currentVersion._id, {
				versionNumber: currentVersion.versionNumber,
				selectedTouchpoint: {
					persona: currentVersion.selectedPersona,
					[`touchpoint${clickedTouchpointIndex}`]: clickedTouchpoint,
				},
				jobToBeDone: "",
				painsAndGains: "",
				userChallenge: "",
				userChallengesJSON: "",
				inspirationTexts: {},
				inspirationImages: {},
			});

			setCurrentVersion(updatedVersion);

			setSelectedTouchpoint({
				[selectedPersona]: clickedTouchpoint,
			});

			setHasSubmittedJobToBeDone(false);
			setHasSubmittedPainsAndGains(false);
		}
	}

	const chartOptions = {
		chart: {
			id: "userJourneyDiagram",

			background: "#fff",
			foreColor: "#333",
			events: {
				click: handleChartEvent,
				touchstart: handleChartEvent,
			},
		},
		markers: {
			size: 5,
			colors: "#8f3a59",
		},
		xaxis: {
			type: "category",
			categories: Object.keys(userJourney).map((key) => `Touchpoint ${key.split("point")[1]}`),
			tooltip: {
				enabled: false,
			},
		},
		yaxis: {
			min: -5,
			max: 5,
		},
		grid: {
			show: true,
			borderColor: "#ccc",
		},
		annotations: {
			yaxis: [
				{
					y: 0,
					borderColor: "#8f3a59",
				},
				{
					y: -5,
					y2: -2,
					fillColor: "#9b3259",
					opacity: 0.2,
				},

				{
					y: 2,
					y2: 5,
					fillColor: "#aadb1e",
					opacity: 0.2,
				},
			],
		},
		tooltip: {
			x: {
				show: true,
				formatter: function (value) {
					const touchpoint = userJourney[`touchpoint${value}`];
					const tooltipText = `<b>Touchpoint ${value}</b>: ${touchpoint.title}</br><b>Thoughts:</b> ${touchpoint.thoughts}</br><b>Actions: </b>${touchpoint.actions}`;

					document.getElementById("tooltip-container").innerHTML = `<span style="font-size: 12px;">${tooltipText}</span>`;

					return "";
				},
			},
			marker: {
				show: true,
			},
		},
		colors: ["#8f3a59"],
	};

	const chartData = {
		series: [
			{
				name: "Satisfaction Level",
				data: Object.values(userJourney).map((touchpoint) => touchpoint.satisfactionLevel),
			},
		],
	};

	return (
		<div id={isSidebarVisible ? "user-journey" : "user-journey-fullscreen"} className="tab-container">
			<div className="persona-container">
				<img
					src={`data:image/png;base64,${currentVersion.personas[selectedPersona].url}`}
					className="user-avatar"
					alt="img"
					onError={({ currentTarget }) => {
						currentTarget.onerror = null;
						currentTarget.src = UserImg;
					}}
				/>
				<div className="persona-info">
					<p className="persona-title">
						<b>{persona.name}</b>
					</p>
					<p className="persona-touchpoint">
						<b>
							{selectedTouchpoint && Object.values(currentVersion.selectedTouchpoint)[0] === selectedPersona
								? Object.values(currentVersion.selectedTouchpoint)[1]?.title
								: "Select Touchpoint"}
						</b>
					</p>

					<p className="persona-need">
						<b>Need: </b>
						{persona.need}
					</p>
					<p className="persona-insight">
						<b>Insight: </b>
						{persona.insight}
					</p>
				</div>
			</div>

			<div className="graph-container">
				<div id="graph">
					<ReactApexChart id="myid" className="graph" series={chartData.series} options={chartOptions} type="line" />
				</div>
				<div id="tooltip-container">
					<span style={{ fontSize: "12px" }}></span>
				</div>
			</div>
		</div>
	);
}

import { useState, useEffect } from "react";

// External Libraries
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faChevronRight, faChevronLeft, faPen } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

// Components
import { InputItem, BounceLoaderPlum, SimpleModal, Alert } from "../../../components";

// Context
import { useFlightContext } from "../../../contexts/FlightContext";

// Utils
import useLocalState from "../../../utils/localState";

// Assets
import { QUESTIONS, PLACEHOLDERS, TERMS_TITLE, TERMS_TEXT } from "../../../assets/feedData";
import { PainsIcon, ObjectiveIcon, ThumbsUpIcon, TeamSpiritIcon, PurposeIcon } from "../../../assets/icons";

// Styles
import "./questionnaire.css";
import { getVersionById, updateVersion, createVersionAndAddToFlight } from "../../../services/flightService";

export default function Questionnaire() {
	const {
		currentFlight,
		setHasSubmittedAnswers,
		setTriggersToFalse,
		currentVersion,
		updateCurrentVersion,
		setCurrentVersion,
		updateCurrentFlight,
	} = useFlightContext();
	const { alert, showAlert, loading, setLoading, hideAlert } = useLocalState();

	const [editableField, setEditableField] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const [formData, setFormData] = useState({
		answer1: "",
		answer2: "",
		answer3: "",
		answer4: "",
		answer5: "",
	});

	const changeHandler = (e) => {
		setFormData((oldData) => ({
			...oldData,
			[e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
		}));
	};

	const questionnaireItems = [
		{
			id: "answer1",
			question: QUESTIONS.QUESTION_1,
			placeholder: PLACEHOLDERS.PLACEHOLDER_1,
			value: formData.answer1,
			onChange: changeHandler,
			disabled: currentVersion?.answers && !(editableField === "answer1"),
			icon: PainsIcon,
		},
		{
			id: "answer2",
			question: QUESTIONS.QUESTION_2,
			placeholder: PLACEHOLDERS.PLACEHOLDER_2,
			value: formData.answer2,
			onChange: changeHandler,
			disabled: currentVersion?.answers && !(editableField === "answer2"),
			icon: ObjectiveIcon,
		},
		{
			id: "answer3",
			question: QUESTIONS.QUESTION_3,
			placeholder: PLACEHOLDERS.PLACEHOLDER_3,
			value: formData.answer3,
			onChange: changeHandler,
			disabled: currentVersion?.answers && !(editableField === "answer3"),
			icon: ThumbsUpIcon,
		},
		{
			id: "answer4",
			question: QUESTIONS.QUESTION_4,
			placeholder: PLACEHOLDERS.PLACEHOLDER_4,
			value: formData.answer4,
			onChange: changeHandler,
			disabled: currentVersion?.answers && !(editableField === "answer4"),
			icon: TeamSpiritIcon,
		},
		{
			id: "answer5",
			question: QUESTIONS.QUESTION_5,
			placeholder: PLACEHOLDERS.PLACEHOLDER_5,
			value: formData.answer5,
			onChange: changeHandler,
			disabled: currentVersion?.answers && !(editableField === "answer5"),
			icon: PurposeIcon,
		},
	];

	// Check if any input field is enabled
	const isAnyFieldEditable = questionnaireItems.some((item) => !item.disabled);

	const handleSubmit = async (e) => {
		e.preventDefault();

		hideAlert();
		setLoading(true);

		try {
			let response;
			if (currentVersion.answers) {
				setTriggersToFalse();

				response = await createVersionAndAddToFlight(currentFlight._id, {
					versionNumber: currentFlight.versions.length + 1,
					answers: formData,
				});
				setCurrentVersion(response.version);
				updateCurrentFlight(response.flight);
			} else {
				response = await updateVersion(currentVersion._id, { versionNumber: currentVersion.versionNumber, answers: formData });
				setCurrentVersion(response);
			}

			if (response.error) {
				showAlert({ text: response.message });
			}

			setHasSubmittedAnswers(true);
			showAlert({ text: "Questionnaire was submitted successfully.", type: "success" });
		} catch (error) {
			showAlert({ text: "Something went wrong. Try again later." });
		} finally {
			setLoading(false);
		}
	};

	const handleOpenModal = () => setIsModalVisible(true);
	const handleCloseModal = () => setIsModalVisible(false);

	const handlePenClick = (id) => {
		setEditableField((prev) => (prev === id ? null : id));
	};

	const handleChangeVersion = async (direction) => {
		const newVersionNumber = direction === "previous" ? currentVersion.versionNumber - 1 : currentVersion.versionNumber + 1;
		const newVersionId = currentFlight.versions[newVersionNumber - 1];

		const newVersion = await getVersionById(newVersionId);

		if (newVersion) {
			setCurrentVersion(newVersion);
		} else {
			console.log(`Version ${newVersionNumber} not found.`);
		}
	};

	// Update form data when the current flight changes
	useEffect(() => {
		setEditableField(null);

		setFormData({
			answer1: currentVersion?.answers?.answer1 ? currentVersion.answers.answer1 : "",
			answer2: currentVersion?.answers?.answer2 ? currentVersion.answers.answer2 : "",
			answer3: currentVersion?.answers?.answer3 ? currentVersion.answers.answer3 : "",
			answer4: currentVersion?.answers?.answer4 ? currentVersion.answers.answer4 : "",
			answer5: currentVersion?.answers?.answer5 ? currentVersion.answers.answer5 : "",
		});
	}, [currentVersion]);

	return (
		<>
			{/* LOADER */}
			<BounceLoaderPlum isLoading={loading} />

			{/* ALERT */}
			<Alert alert={alert} />

			{/* MODAL */}
			{isModalVisible && <SimpleModal title={TERMS_TITLE} messages={TERMS_TEXT} isFeedbackAllowed={true} closeHandler={handleCloseModal} />}

			<form id="questionnaire" className="tab-container" onSubmit={(e) => handleSubmit(e)}>
				{questionnaireItems.map((item) => (
					<div style={{ display: "flex", gap: "1rem", alignItems: "center", justifyContent: "space-between" }} key={item.id}>
						<InputItem
							id={item.id}
							icon={item.icon}
							question={item.question}
							placeholder={item.placeholder}
							value={item.value}
							onChange={item.onChange}
							disabled={item.disabled}
						/>

						<FontAwesomeIcon
							className="edit-icon"
							onClick={() => handlePenClick(item.id)}
							icon={faPen}
							data-tooltip-id="edit-tooltip"
							data-tooltip-content="Edit Field"
						/>
						<Tooltip id="edit-tooltip" />
					</div>
				))}

				<div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
					<button
						type="submit"
						style={{ width: "100%" }}
						className={`submit-btn ${currentVersion?.answers && !isAnyFieldEditable ? "submit-btn-disabled" : ""}`}
						disabled={currentVersion?.answers && !isAnyFieldEditable}>
						Submit
					</button>

					<FontAwesomeIcon
						icon={faCircleQuestion}
						onClick={() => handleOpenModal({})}
						style={{ color: "#ddc8d0", fontSize: "2rem", cursor: "pointer" }}
						data-tooltip-id="info-tooltip"
						data-tooltip-content="Terms and Limitations"
					/>
					<Tooltip id="info-tooltip" />
				</div>

				{/* VERSION */}
				<div
					style={{
						display: "flex",
						marginTop: "1rem",
						marginRight: "auto",
						marginBottom: "auto",
						marginLeft: "auto",
						alignItems: "center",
					}}>
					{currentVersion?.versionNumber > 1 ? (
						<FontAwesomeIcon icon={faChevronLeft} className="version-control" onClick={() => handleChangeVersion("previous")} />
					) : (
						<FontAwesomeIcon icon={faChevronLeft} className="version-control inactive" />
					)}

					<div style={{ padding: "0.5rem", backgroundColor: "#8f3a59", color: "white", borderRadius: "0.5rem" }}>
						Version {currentVersion?.versionNumber} / {currentFlight.versions?.length}
					</div>

					{currentVersion?.versionNumber < currentFlight.versions.length ? (
						<FontAwesomeIcon icon={faChevronRight} className="version-control" onClick={() => handleChangeVersion("next")} />
					) : (
						<FontAwesomeIcon icon={faChevronRight} className="version-control inactive" />
					)}
				</div>
			</form>
		</>
	);
}

import React from "react";
import { Link } from "react-router-dom";

// External Libraries
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faLightbulb } from "@fortawesome/free-solid-svg-icons";

// Context
import { useAuthContext } from "../../contexts/AuthContext";

// Components
import { Navigation, Feedback } from "../../components";

// Styles
import "./home.css";
import HomepageImg from "../../assets/homepage.svg";

// Main Home Component
export default function Home() {
	const { user } = useAuthContext();

	return (
		<>
			<Navigation />

			<div className="app-container">
				{user ? <UserContent /> : <HeroSection />}

				<Feedback />
			</div>
		</>
	);
}

// Component for authenticated users
function UserContent() {
	return (
		<>
			<Link to="/inspiration-cards/" className="app-card">
				<h2>Inspiration Cards</h2>
				<FontAwesomeIcon icon={faLightbulb} className="app-icon" />
			</Link>

			<Link to="/design-flight/" className="app-card">
				<h2>Innovation Flightdeck</h2>
				<FontAwesomeIcon icon={faPaperPlane} className="app-icon" />
			</Link>
		</>
	);
}

// Component for users who are not authenticated
function HeroSection() {
	return (
		<div className="hero-section">
			<div className="hero-text">
				<h1>
					Become a <span className="text-dark">Design Thinking</span> Expert by transforming Challenges into Innovations
				</h1>
				<p>
					Welcome to your journey of becoming a{" "}
					<span className="text-dark">
						<b>Design Thinking</b>
					</span>{" "}
					Expert. Unleash your creativity, tackle challenges head-on, and pave the way for innovative solutions.
				</p>
				<Link to="/auth/register" className="btn btn-dark" style={{ marginTop: "0.5rem", display: "inline-block" }}>
					Sign Up Now
				</Link>
			</div>

			<img src={HomepageImg} alt="Design Thinking Hero" className="hero-img" />
		</div>
	);
}

import { useState, useEffect } from "react";
import { useCollapse } from "react-collapsed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

import TextToSpeech from "../../Controls/TextToSpeech";
import SolidLine from "../SolidLine";
import { createMarkup, formatContent } from "../../../utils/helpers";
import "./collapsible.css";

export default function Collapsible({ title, content }) {
	const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
	const [formattedContent, setFormattedContent] = useState("");

	useEffect(() => {
		if (content && typeof content === "string") {
			setFormattedContent(formatContent(content));
		}
	}, [content]);

	return (
		<div className="collapsible">
			<div className={`header ${isExpanded ? "header-expanded" : ""}`} {...getToggleProps()}>
				<h3>{title}</h3>

				{isExpanded ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
			</div>
			<div {...getCollapseProps()}>
				<div className={`content ${isExpanded ? "content-expanded" : ""}`}>
					{content && typeof content === "string" ? (
						<div dangerouslySetInnerHTML={createMarkup(formattedContent)} />
					) : (
						<div style={{ whiteSpace: "pre-wrap" }}>{content}</div>
					)}

					<SolidLine isDark={true} />
					<TextToSpeech id={title} text={formattedContent} />
				</div>
			</div>
		</div>
	);
}

import loading from "../../../assets/Loading_icon.gif";
import NoImage from "../../../assets/no-image.png";
import SmallLoader from "../../UI/Loader/SmallLoader";
import { SolidLine, TextToSpeech } from "../../../components";
import { randomId } from "../../../utils/helpers";

export default function Card({ title, text, img_url, isScenariosLoading, isImagesLoading }) {
	const id = randomId();

	return (
		<div className="card-item">
			<h4 className="card-title">{title}:</h4>
			<div>
				{isImagesLoading ? (
					<img className="card-img" src={loading} alt="loading..." />
				) : (
					img_url && (
						<img
							className="card-img"
							src={`data:image/png;base64,${img_url}`}
							alt="scenario img"
							onError={({ currentTarget }) => {
								currentTarget.onerror = null;
								currentTarget.src = NoImage;
							}}
						/>
					)
				)}

				<div className="card-text">
					{isScenariosLoading ? (
						<SmallLoader />
					) : (
						<>
							<p>{text}</p>
							<SolidLine />
							<TextToSpeech id={id} text={text} />
						</>
					)}
				</div>
			</div>
		</div>
	);
}

import { useState, useEffect } from "react";

export const useSpeechSynthesisVoice = () => {
	const [voice, setVoice] = useState(null);

	useEffect(() => {
		const handleVoicesChanged = () => {
			const voices = window.speechSynthesis.getVoices();
			const savedVoiceName = localStorage.getItem("selectedVoice") || "Aaron";
			const savedVoice = voices.find((v) => v.name === savedVoiceName);
			setVoice(savedVoice);
		};

		// Listen for the voiceschanged event
		window.speechSynthesis.addEventListener("voiceschanged", handleVoicesChanged);

		// Call the handler manually in case the voices have already been loaded
		handleVoicesChanged();

		// Clean up the event listener when the component unmounts
		return () => {
			window.speechSynthesis.removeEventListener("voiceschanged", handleVoicesChanged);
		};
	});

	return { voice, setVoice };
};

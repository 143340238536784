import { useState } from "react";

const useLocalState = () => {
	const [alert, setAlert] = useState({
		show: false,
		text: "",
		type: "danger",
	});
	const [loading, setLoading] = useState(false);

	const showAlert = ({ text, type = "danger" }) => {
		setAlert({ show: true, text, type });

		setTimeout(() => {
			hideAlert();
		}, 3000);
	};
	const hideAlert = () => {
		setAlert({ show: false, text: "", type: "danger" });
	};
	return {
		alert,
		showAlert,
		loading,
		setLoading,
		hideAlert,
	};
};

export default useLocalState;

const rootUrl = process.env.REACT_APP_ROOT_URL;

export const login = async (email, password) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/auth/login`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify({
				email,
				password,
			}),
		});
		return response;
	} catch (err) {
		console.error(err);
	}
};

export const register = async (email, password) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/auth/register`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify({
				email,
				password,
			}),
		});
		return response;
	} catch (err) {
		console.error(err);
	}
};

export const logout = async () => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/auth/logout`, {
			method: "DELETE",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
		});
		console.log(response);
		return response;
	} catch (err) {
		console.error(err);
	}
};

export const getCurrentUser = async () => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/users/showMe`, {
			method: "GET",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
		});
		return response;
	} catch (err) {
		console.error(err);
	}
};

export const getUserCount = async () => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/users/getUserCount`, {
			method: "GET",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
		});
		return response;
	} catch (err) {
		console.error(err);
	}
};

export const forgotPassword = async (email) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/auth/forgot-password`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify({
				email,
			}),
		});
		return response;
	} catch (err) {
		console.error(err);
	}
};

export const resetPassword = async (token, email, password) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/auth/reset-password`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify({
				token,
				email,
				password,
			}),
		});
		return response;
	} catch (err) {
		console.error(err);
	}
};

export const verifyEmail = async (token, email) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/auth/verify-email`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify({
				token,
				email,
			}),
		});
		return response;
	} catch (err) {
		console.error(err);
	}
};

export const updateUserPassword = async (oldPassword, newPassword) => {
	try {
		const response = await fetch(`${rootUrl}/api/v1/users/updateUserPassword`, {
			method: "PATCH",
			credentials: "include",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify({
				oldPassword,
				newPassword,
			}),
		});
		return response;
	} catch (err) {
		console.error(err);
	}
};

import React, { useState } from "react";
//import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../services/authService";

import { BounceLoaderPlum } from "../../components";
import useLocalState from "../../utils/localState";

import LogoPlum from "../../assets/logo-plum.png";
import "./auth.css";

export default function ForgotPassword() {
	//const navigate = useNavigate();

	const [isSent, setIsSent] = useState(false);
	const { alert, showAlert, loading, setLoading, hideAlert } = useLocalState();

	const [formData, setFormData] = useState({
		email: "",
	});

	const changeHandler = (e) => {
		setFormData((oldData) => ({
			...oldData,
			[e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
		}));
	};

	const submitHandler = async (e) => {
		e.preventDefault();

		if (!formData.email) {
			showAlert({ text: "All fields are required" });
			return;
		}

		hideAlert();
		setLoading(true);

		try {
			const response = await forgotPassword(formData.email);

			setFormData({ email: "" });

			if (response.status === 200) {
				setLoading(false);
				setIsSent(true);
			} else {
				const message = await response.json();
				showAlert({ text: message.msg });
			}

			setLoading(false);
		} catch (error) {
			showAlert({ text: "Something went wrong. Try again later" });
			setLoading(false);
		}
	};

	return (
		<>
			<BounceLoaderPlum isLoading={loading} />

			{alert.show && <div className="message-container">{alert.text}</div>}

			<div id="login">
				<div className="auth-left">
					<a href="/">
						<div className="auth-logo">
							<img src={LogoPlum} alt="auth-logo" />
						</div>
						<h2 className="auth-title">Innovation Flightdeck</h2>
					</a>
				</div>

				<div className="auth-right">
					{!isSent ? (
						<div className="auth-form-container">
							<h1 className="text-plum">Reset the password</h1>
							<h2>of your account</h2>
							<div className="separator"></div>

							<form className="form" onSubmit={submitHandler}>
								<div>
									<label htmlFor="email">
										<b>Email</b>
									</label>
									<input
										id="email"
										type="email"
										placeholder="johndoe@mail.com"
										name="email"
										value={formData.email}
										onChange={changeHandler}
										className="auth-input"
										required
									/>
								</div>

								<button className="auth-btn" type="submit">
									Get reset password link
								</button>
							</form>
						</div>
					) : (
						<div className="auth-form-container">
							<h1 className="text-plum">Reset password link</h1>
							<h2>was sent to your email address</h2>
							<div className="separator"></div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

import { useState, useEffect } from "react";

export const useSpeechSynthesisPitch = () => {
	const [pitch, setPitch] = useState(null);

	useEffect(() => {
		const handleVoicesChanged = () => {
			const savedVoicePitch = localStorage.getItem("selectedPitch") || 1;
			setPitch(savedVoicePitch);
		};

		// Call the handler manually in case the voices have already been loaded
		handleVoicesChanged();
	});

	return { pitch, setPitch };
};

import { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";

import { useSpeechSynthesisVoice, useSpeechSynthesisPitch, useSpeechSynthesisRate } from "../../hooks";

// External Libraries
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh, faVolumeXmark } from "@fortawesome/free-solid-svg-icons";

import "./controls.css";

const TextToSpeech = ({ id, text }) => {
	const [utterance, setUtterance] = useState(null);
	const { voice } = useSpeechSynthesisVoice();
	const { rate } = useSpeechSynthesisRate();
	const { pitch } = useSpeechSynthesisPitch();

	const [isSpeaking, setIsSpeaking] = useState(false);

	useEffect(() => {
		const synth = window.speechSynthesis;
		const u = new SpeechSynthesisUtterance(text);

		u.onend = () => {
			setIsSpeaking(false);
		};

		setUtterance(u);

		return () => {
			synth.cancel();
		};
	}, [text]);

	const handleToggle = () => {
		const synth = window.speechSynthesis;

		if (isSpeaking) {
			synth.cancel();
			setIsSpeaking(false);
		} else {
			utterance.voice = voice;
			utterance.rate = rate;
			utterance.pitch = pitch;

			synth.speak(utterance);
			setIsSpeaking(true);
		}
	};

	return (
		<div>
			<button onClick={handleToggle} className="control-btn">
				{isSpeaking ? (
					<>
						<FontAwesomeIcon
							icon={faVolumeXmark}
							style={{ outline: "none" }}
							data-tooltip-id={`${id}-stop-tooltip`}
							data-tooltip-content="Stop"
						/>
						<Tooltip id={`${id}-stop-tooltip`} />
					</>
				) : (
					<>
						<FontAwesomeIcon
							icon={faVolumeHigh}
							style={{ outline: "none" }}
							data-tooltip-id={`${id}-play-tooltip`}
							data-tooltip-content="Play"
						/>
						<Tooltip id={`${id}-play-tooltip`} />
					</>
				)}
			</button>
		</div>
	);
};

export default TextToSpeech;

import React from "react";

export default function InputSection({ submitHandler, userInput, setUserInput }) {
	return (
		<section id="input-section" className="inspiration-container">
			<h2>What is your Design Challenge?</h2>
			<div>
				<form className="dc-form" onSubmit={submitHandler}>
					<textarea
						className="input-field"
						type="text"
						rows="5"
						placeholder="Write your Design Challenge here"
						value={userInput}
						onChange={(e) => setUserInput(e.target.value)}
					/>
					<button className="btn btn-dark" type="submit">
						Generate Abstract
					</button>
				</form>
			</div>
		</section>
	);
}

import emailjs from "@emailjs/browser";

const url = process.env.REACT_APP_NEXTCLOUD_ROOT_URL;
const user = process.env.REACT_APP_NEXTCLOUD_AUTH;
const boardId = process.env.REACT_APP_NEXTCLOUD_BOARD_ID;
const stackId = process.env.REACT_APP_NEXTCLOUD_STACK_ID;

const serviceId = process.env.REACT_APP_SERVICE_ID;
const templateId = process.env.REACT_APP_TEMPLATE_ID;
const publicKey = process.env.REACT_APP_PUBLIC_KEY;

export const createCard = async (title, description) => {
	try {
		const response = await fetch(`${url}/boards/${boardId}/stacks/${stackId}/cards`, {
			method: "POST",
			headers: {
				"Content-type": "application/json",
				Authorization: user,
			},
			body: JSON.stringify({
				title,
				type: "plain",
				order: 999,
				description,
			}),
		});
		return response;
	} catch (err) {
		console.error(err);
	}
};

export const sendFeedbackEmail = (form) => emailjs.sendForm(serviceId, templateId, form, publicKey);

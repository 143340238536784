import { Routes, Route } from "react-router-dom";

// Context
import { useAuthContext } from "./contexts/AuthContext";
import { FlightContextProvider } from "./contexts/FlightContext";
import { AppContextProvider } from "./contexts/AppContext";

// Components
import { Home, Login, Register, DesignFlight, Share, NotFound, Profile, ForgotPassword, ResetPassword, VerifyEmail, InspirationCards } from "./pages";
import { BounceLoaderPlum } from "./components";

// Utils
import ProtectedRoute from "./utils/ProtectedRoute";

// Styles
import "./App.css";

function App() {
	const { user, isLoading } = useAuthContext();

	return (
		<AppContextProvider>
			<FlightContextProvider user={user}>
				<main>
					<BounceLoaderPlum isLoading={isLoading} />

					<Routes>
						<Route path="/" element={<Home />} />

						<Route path="/auth/login" element={<Login />} />
						<Route path="/auth/register" element={<Register />} />
						<Route path="/auth/profile" element={<Profile />} />

						<Route path="/auth/forgot-password" element={<ForgotPassword />} />
						<Route path="/auth/reset-password" element={<ResetPassword />} />
						<Route path="/auth/verify-email" element={<VerifyEmail />} />

						<Route element={<ProtectedRoute />}>
							<Route path="/design-flight/" element={<DesignFlight />} />
						</Route>

						<Route element={<ProtectedRoute />}>
							<Route path="/inspiration-cards/" element={<InspirationCards />} />
						</Route>

						<Route path="/share/:id" element={<Share />} />

						<Route path="*" element={<NotFound />} />
					</Routes>
				</main>
			</FlightContextProvider>
		</AppContextProvider>
	);
}

export default App;

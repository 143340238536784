import { Outlet, Navigate } from "react-router-dom";

import { useAuthContext } from "../contexts/AuthContext";

const ProtectedRoute = ({ children }) => {
	const { user, isLoading } = useAuthContext();

	if (!isLoading) {
		if (!user) {
			return <Navigate to={"/auth/login"} replace />;
		}
	}

	return children ? children : <Outlet />;
};

export default ProtectedRoute;

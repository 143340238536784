import React, { useState, useRef, useEffect } from "react";

import { createCard, sendFeedbackEmail } from "../../services/feedbackService";

import "./feedback.css";
import { useAuthContext } from "../../contexts/AuthContext";

export default function Feedback() {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isFeedbackSent, setIsFeedbackSent] = useState(false);

	const { user } = useAuthContext();

	const form = useRef();

	const [formData, setFormData] = useState({
		feedbackType: "",
		name: "",
		email: "",
		message: "",
	});

	const changeHandler = (e) => {
		setFormData((oldData) => ({
			...oldData,
			[e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
		}));
	};

	const handleOpenModal = () => {
		setIsModalVisible(true);
	};

	const handleCloseModal = () => {
		setIsModalVisible(false);
	};

	const handleSendFeedback = (e) => {
		e.preventDefault();

		setIsFeedbackSent(true);

		sendFeedbackEmail(form.current).then(
			(result) => {
				console.log(result.text);
				console.log("Feedback sent!", formData);

				setTimeout(() => {
					setIsFeedbackSent(false);
					setIsModalVisible(false);
					setFormData({
						feedbackType: "",
						name: "",
						email: "",
						message: "",
					});
				}, 5000);
			},
			(error) => {
				console.log(error.text);
			}
		);

		createCard(
			`${formData.feedbackType} from ${formData.name}`,
			`\nFeedback Type: ${formData.feedbackType}
			\nName: ${formData.name}
			\nEmail: ${formData.email}
			\nMessage: ${formData.message}`
		);
	};

	useEffect(() => {
		formData.email = user?.email;
	}, [user]);

	return (
		<>
			{isModalVisible && (
				<div className="modal">
					<div className="modal-content">
						<span className="close" onClick={handleCloseModal}>
							&times;
						</span>
						<h2 className="modal-title text-dark">How can we help you?</h2>

						<form id="feedback-form" ref={form} onSubmit={handleSendFeedback}>
							<div>
								<label>
									<b>Feedback Type</b>
								</label>
								<div className="radio-btns">
									<input type="radio" id="question" name="feedbackType" value="Question" onChange={changeHandler} required />
									<label htmlFor="question">Question</label>

									<input type="radio" id="bug" name="feedbackType" value="Bug Report" onChange={changeHandler} required />
									<label htmlFor="bug">Bug Report</label>

									<input type="radio" id="feature" name="feedbackType" value="Feature Request" onChange={changeHandler} required />
									<label htmlFor="feature">Feature Request</label>
								</div>
							</div>

							<div>
								<label htmlFor="name">
									<b>Name</b>
								</label>
								<div>
									<input id="subject" type="text" name="name" value={formData.name} onChange={changeHandler} required />
								</div>
							</div>

							<div>
								<label htmlFor="email">
									<b>Email</b>
								</label>
								<div>
									<input id="email" type="email" name="email" value={formData.email} onChange={changeHandler} required />
								</div>
							</div>

							<div>
								<label htmlFor="message">
									<b>Message</b>
								</label>
								<div>
									<textarea id="message" type="text" name="message" value={formData.message} onChange={changeHandler} required />
								</div>
							</div>

							<button className={`btn ${isFeedbackSent ? "btn-light" : "btn-dark"}`} disabled={isFeedbackSent}>
								Send
							</button>

							{isFeedbackSent && <span className="copy-feedback">Message was successfully sent.</span>}
						</form>
					</div>
				</div>
			)}

			<div id="feedback-container" onClick={() => handleOpenModal({})}>
				Feedback
			</div>
		</>
	);
}

import React from "react";

// External Libraries
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

// Context
import { useFlightContext } from "../../contexts/FlightContext";
import { useAppContext } from "../../contexts/AppContext";

// Utils
import { TAB_NAMES } from "../../assets/feedData";
import { createPDF, formatSection, formatPersonas, formatUserJourney, formatPDFContent } from "../../utils/helpers";

const ActiveFlight = ({ tabs }) => {
	const { isUserJourneyLoading, currentFlight, activeTabIndex, setActiveTabIndex, currentVersion } = useFlightContext();
	const { isSidebarVisible, handleSidebar } = useAppContext();

	const icon = isSidebarVisible ? faChevronLeft : faChevronRight;
	const tooltipId = isSidebarVisible ? "collapse-tooltip" : "expand-tooltip";
	const tooltipContent = isSidebarVisible ? "Close Sidebar" : "Open Sidebar";

	const handleTabClick = (index) => {
		setActiveTabIndex(index);
	};

	// const handleGeneratePDF = () => {
	// 	const selectedUserJourney = currentVersion.personas[currentVersion.selectedPersona].touchpoints;
	// 	const personaName = currentVersion.personas[currentVersion.selectedPersona].name;

	// 	const tableData = [
	// 		formatSection("Version Number", currentVersion.versionNumber),
	// 		formatSection("Created", new Date(currentVersion.createdAt).toLocaleString()),

	// 		formatSection(TAB_NAMES.SUMMARY, formatPDFContent(currentVersion.summary)),
	// 		formatSection(TAB_NAMES.DESIGN_CHALLENGE, formatPDFContent(currentVersion.designChallenge)),
	// 		formatSection(TAB_NAMES.MAIN_PROBLEMS, formatPDFContent(currentVersion.mainProblems)),
	// 		formatSection(TAB_NAMES.GOOD_PRACTICES, formatPDFContent(currentVersion.goodPractices)),
	// 		formatSection(TAB_NAMES.PERSONAS, formatPersonas(currentVersion.personas)),
	// 		formatSection(TAB_NAMES.USER_JOURNEY, formatUserJourney(selectedUserJourney, personaName)),
	// 	];

	// 	createPDF(tableData);
	// };

	const handleGeneratePDF = () => {
		const tableData = [
			// formatSection("Version Number", currentVersion.versionNumber),
			// formatSection("Created", new Date(currentVersion.createdAt).toLocaleString()),

			// formatSection("Image", "put image here"),
			formatSection(TAB_NAMES.CONCEPT_POSTER, formatPDFContent(currentVersion.conceptPoster)),
		];

		createPDF(tableData, `data:image/png;base64,${currentVersion.conceptPosterImageURL}`);
	};

	return (
		<>
			<div className="sidebar-control" onClick={handleSidebar}>
				<FontAwesomeIcon data-tooltip-id={tooltipId} data-tooltip-content={tooltipContent} icon={icon} className="chevron" />
				<Tooltip id={tooltipId} />
			</div>

			<div className={isSidebarVisible ? "flight-active" : "flight-active-fullscreen"}>
				<div className="tabs-container">
					{tabs.map((tab, index) => (
						<div
							key={index}
							onClick={() => {
								handleTabClick(index);
								tab.onClick && tab.onClick();
							}}
							className={`tab ${activeTabIndex === index ? "tab--active" : ""} ${tab.enabled ? "" : "tab--disabled"}`}>
							{tab.title}
						</div>
					))}
					<FontAwesomeIcon
						icon={faFilePdf}
						onClick={handleGeneratePDF}
						// className={`pdf-icon ${
						// 	currentVersion?.summary &&
						// 	currentVersion?.designChallenge &&
						// 	currentVersion?.mainProblems &&
						// 	currentVersion?.goodPractices &&
						// 	currentVersion?.personas &&
						// 	currentVersion?.selectedPersona &&
						// 	!isUserJourneyLoading
						// 		? "pdf-icon--enabled"
						// 		: "pdf-icon--disabled"
						// }`}
						className={`pdf-icon ${currentVersion?.conceptPoster ? "pdf-icon--enabled" : "pdf-icon--disabled"}`}
					/>
				</div>

				{/* Render the content of the active tab */}
				{React.cloneElement(tabs[activeTabIndex].content, {})}
			</div>
		</>
	);
};

export default ActiveFlight;

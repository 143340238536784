import React from "react";
import BounceLoader from "react-spinners/BounceLoader";

export default function BounceLoaderPlum({ isLoading }) {
	return (
		<div className={isLoading ? "loading-mask" : ""}>
			<BounceLoader
				color={"#8f3a59"}
				loading={isLoading}
				size={50}
				aria-label="Loading..."
				data-testid="loader"
			/>
		</div>
	);
}

import { ErrorBoundary } from "react-error-boundary";

import ErrorImg from "../../assets/error.svg";

function fallbackRender({ error, resetErrorBoundary }) {
	return (
		<div className="container">
			<img src={ErrorImg} alt="error-img" className="error-img" />

			<p className="not-found-text">
				Aaaah! Something went wrong.
				<br />
				Brace yourself till we get the error fixed. You may also refresh the page or try again later.
			</p>

			<button className="btn btn-dark" onClick={resetErrorBoundary}>
				Refresh
			</button>
		</div>
	);
}

export default function AppErrorBoundary({ children }) {
	return (
		<ErrorBoundary
			fallbackRender={fallbackRender}
			onReset={() => {
				console.log("Error boundary has been reset");
			}}>
			{children}
		</ErrorBoundary>
	);
}

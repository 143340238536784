import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/authService";

import { BounceLoaderPlum, ShowPasswordButton } from "../../components";
import useLocalState from "../../utils/localState";

import LogoPlum from "../../assets/logo-plum.png";
import "./auth.css";

export default function ForgotPassword() {
	const navigate = useNavigate();
	const { search } = useLocation();

	const params = new URLSearchParams(search);
	const token = params.get("token");
	const email = params.get("email");

	const [isSent, setIsSent] = useState(false);
	const { alert, showAlert, loading, setLoading, hideAlert } = useLocalState();

	const [showPassword, setShowPassword] = useState(false);
	const [formData, setFormData] = useState({
		password: "",
	});

	const changeHandler = (e) => {
		setFormData((oldData) => ({
			...oldData,
			[e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
		}));
	};

	const submitHandler = async (e) => {
		e.preventDefault();

		if (!formData.password) {
			showAlert({ text: "All fields are required" });
			return;
		}

		hideAlert();
		setLoading(true);

		try {
			const response = await resetPassword(token, email, formData.password);

			setFormData({ password: "" });

			if (response.status === 200) {
				setLoading(false);
				setIsSent(true);

				setTimeout(() => {
					navigate("/auth/login");
				}, 5000);
			} else {
				const message = await response.json();
				showAlert({ text: message.msg });
			}

			setLoading(false);
		} catch (error) {
			showAlert({ text: "Something went wrong. Try again later" });
			setLoading(false);
		}
	};

	const handleShowPassword = (e) => {
		setShowPassword(!showPassword);
	};

	return (
		<>
			<BounceLoaderPlum isLoading={loading} />

			{alert.show && <div className="message-container">{alert.text}</div>}

			<div id="login">
				<div className="auth-left">
					<a href="/">
						<div className="auth-logo">
							<img src={LogoPlum} alt="auth-logo" />
						</div>
						<h2 className="auth-title">Innovation Flightdeck</h2>
					</a>
				</div>

				<div className="auth-right">
					{!isSent ? (
						<div className="auth-form-container">
							<h1 className="text-plum">Reset the password</h1>
							<h2>of your account</h2>
							<div className="separator"></div>

							<form className="form" onSubmit={submitHandler}>
								<div className="relative">
									<label htmlFor="email">
										<b>Password</b>
									</label>
									<input
										id="email"
										type={showPassword ? "text" : "password"}
										name="password"
										value={formData.password}
										onChange={changeHandler}
										className="auth-input"
										required
									/>
									<ShowPasswordButton showPassword={showPassword} onClick={handleShowPassword} />
								</div>

								<button className="auth-btn" type="submit">
									Change password
								</button>
							</form>
						</div>
					) : (
						<div className="auth-form-container">
							<h1 className="text-plum">Password changed</h1>
							<h2>you will be redirected to login shortly</h2>
							<div className="separator"></div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

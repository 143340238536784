import { useFlightContext } from "../../../contexts/FlightContext";
import { TabSection } from "../../../components";

import "./good-practices.css";

export default function GoodPractices() {
	const { currentFlight, currentVersion } = useFlightContext();

	return (
		<div id="good-practices" className="tab-container">
			<TabSection title="Good Practices" content={currentVersion.goodPractices} />
		</div>
	);
}

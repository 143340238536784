import React, { useState, useEffect } from "react";

import { generateImage, updateVersion } from "../../../services/flightService";

import { useFlightContext } from "../../../contexts/FlightContext";
import { SmallLoader } from "../..";

import "./personas.css";

export default function Personas() {
	const { editSingleFlight, fetchUserJourney, isUserJourneyLoading, updateCurrentFlight, currentVersion, setCurrentVersion } = useFlightContext();

	const [selectedPersona, setSelectedPersona] = useState(currentVersion.selectedPersona);
	const [isLoading, setIsLoading] = useState(false);

	async function handleClick(persona) {
		const updatedVersion = await updateVersion(currentVersion._id, {
			versionNumber: currentVersion.versionNumber,
			selectedPersona: persona,

			selectedTouchpoint: "",
			jobToBeDone: "",
			painsAndGains: "",
			userChallenge: "",
			userChallengesJSON: "",
			inspirationTexts: {},
			inspirationImages: {},
			ideas: "",
			selectedIdea: "",
			conceptPoster: "",
		});

		setCurrentVersion(updatedVersion);

		setSelectedPersona(persona);

		fetchUserJourney(persona);
	}

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);

			let updatedPersonas = {};

			await Promise.all(
				Object.entries(currentVersion.personas).map(async (persona) => {
					const name = Object.values(persona)[1].name;
					const description = Object.values(persona)[0];
					const need = Object.values(persona)[1].need;

					persona[1].url = await generateImage(`a portrait of ${name} who's need is ${need}}`);
					updatedPersonas[description] = persona[1];
				})
			);

			const updatedVersion = await updateVersion(currentVersion._id, {
				versionNumber: currentVersion.versionNumber,
				personas: updatedPersonas,
			});

			setCurrentVersion(updatedVersion);

			setIsLoading(false);
		};

		if (currentVersion.personas !== undefined && !Object.entries(currentVersion.personas)[0][1].hasOwnProperty("url")) {
			fetchData();
		}
	}, [currentVersion, editSingleFlight, updateCurrentFlight]);

	return (
		<div id="points-of-view" className="tab-container">
			{currentVersion.personas ? (
				<div className="user-cards-container">
					{Object.keys(currentVersion.personas).map((persona, index) => (
						<div key={index} className={`user-card ${selectedPersona === persona && "user-card-selected"}`}>
							<div className="user-card-top">
								{!isLoading ? (
									<img src={`data:image/png;base64,${currentVersion.personas[persona].url}`} className="user-avatar" alt="img" />
								) : (
									<SmallLoader style={{ margin: "auto" }} />
								)}

								<h4 className="user-name">
									{persona.split(":")[1]} {currentVersion.personas[persona].name}
								</h4>
							</div>
							<div className="user-card-bottom">
								<p className="user-info user-need">
									<b>Need: </b>
									{currentVersion.personas[persona].need}
								</p>
								<p className="user-info user-insight">
									<b>Insight: </b>
									{currentVersion.personas[persona].insight}
								</p>
							</div>
							<button
								className={`user-card-action ${(isLoading || isUserJourneyLoading) && "user-card-action-disabled"}`}
								disabled={isLoading || isUserJourneyLoading || selectedPersona === persona}
								onClick={() => handleClick(persona)}>
								{isLoading || isUserJourneyLoading ? (
									<SmallLoader style={{ margin: "auto" }} />
								) : selectedPersona === persona ? (
									"Selected"
								) : (
									"Get User Journey"
								)}
							</button>
						</div>
					))}
				</div>
			) : (
				<div>
					<h2>Personas</h2>
					<SmallLoader />
				</div>
			)}
		</div>
	);
}

import { useState } from "react";

import { SmallLoader, SolidLine, TextToSpeech } from "../../../components";
import { askGPT, updateVersion } from "../../../services/flightService";
import { useFlightContext } from "../../../contexts/FlightContext";
import { randomId } from "../../../utils/helpers";

function DesignChallengeAbstract({ generate }) {
	const id = randomId();
	const { currentVersion, setCurrentVersion } = useFlightContext();

	const [isLoading, setIsLoading] = useState(false);

	const handleTryAgain = async function (challenge) {
		setIsLoading(true);

		const userChallengeAbstract = await askGPT(
			[],
			`Reformulate the following question "${challenge}" by generalizing subject, objects, and activities while keeping all adjectives.`
		);

		const updatedVersion = await updateVersion(currentVersion._id, {
			versionNumber: currentVersion.versionNumber,
			userChallengeAbstract: userChallengeAbstract,
			inspirationTexts: {},
			inspirationImages: {},
			ideas: "",
			selectedIdea: "",
		});

		setCurrentVersion(updatedVersion);

		setIsLoading(false);
	};

	return (
		<div>
			<h2>Your abstracted Design Challenge:</h2>
			{isLoading ? (
				<SmallLoader />
			) : (
				<>
					<p>{currentVersion.userChallengeAbstract}</p>
					<SolidLine />
					<TextToSpeech id={id} text={currentVersion.userChallengeAbstract} />
				</>
			)}

			{currentVersion.userChallengeAbstract && !isLoading && (
				<div className="btn-group">
					<button className="btn btn-dark" onClick={() => handleTryAgain(currentVersion.userChallenge)}>
						Generate New Abstract
					</button>
					<button className="btn btn-dark" onClick={generate}>
						Generate Inspirations
					</button>
				</div>
			)}
		</div>
	);
}

export default DesignChallengeAbstract;

import { useEffect, useState } from "react";

import { useFlightContext } from "../../../contexts/FlightContext";
import { askGPT, updateVersion, generateImage } from "../../../services/flightService";
import { BounceLoaderPlum } from "../../../components";

import loading from "../../../assets/Loading_icon.gif";
import NoImage from "../../../assets/no-image.png";

import "./concept-poster.css";

export default function ConceptPoster() {
	const { currentVersion, setCurrentVersion } = useFlightContext();

	const [selectedIdea, setSelectedIdea] = useState(currentVersion.selectedIdea || "");
	const [conceptPoster, setConceptPoster] = useState(currentVersion.ConceptPoster ? currentVersion.conceptPoster : []);
	const [img_url, setImgUrl] = useState(currentVersion.conceptPosterImageURL ? currentVersion.conceptPosterImageURL : "");

	const [isLoading, setIsLoading] = useState(false);

	const keywords = ["Product Name:", "Persona:", "Needs Addressed:", "Context:", "Solution Approach:", "Key Benefits:"];

	const handleClick = async () => {
		console.log("Generating concept poster...");

		setIsLoading(true);
		setImgUrl("");
		setConceptPoster([]);

		const textPrompt = `Describe the selected idea "${selectedIdea}" as a concept poster. \
		Start by giving the idea a product name. Identify the persona and explain the specific needs it addresses, \
		as well as the context in which the solution is applied. \
		Then, in no more than two sentences, describe the solution approach and highlight the key benefits it provides. \
		Make sure to include the following keywords: Product Name, Persona, Needs Addressed, Context, Solution Approach, Key Benefits.`;

		const newConceptPoster = await askGPT([], textPrompt);
		setConceptPoster(newConceptPoster.split("\n"));

		const imagePrompt = await askGPT([], `Generate a prompt that helps DALLE-3 to create an image of the following idea ${selectedIdea}`);

		setIsLoading(false);

		const newImgUrl = await generateImage(`${imagePrompt}`);
		setImgUrl(newImgUrl);

		const updatedVersion = await updateVersion(currentVersion._id, {
			versionNumber: currentVersion.versionNumber,
			conceptPoster: newConceptPoster,
			conceptPosterImageURL: newImgUrl,
		});

		setCurrentVersion(updatedVersion);
	};

	useEffect(() => {
		setSelectedIdea(currentVersion.selectedIdea);
		setConceptPoster(currentVersion.conceptPoster ? currentVersion.conceptPoster.split("\n") : []);
	}, [currentVersion]);

	return (
		<div id="concept-poster" className="tab-container">
			<h2>Your selected idea:</h2>
			<p>{selectedIdea}</p>

			<BounceLoaderPlum isLoading={isLoading} />

			<div className="btn-group">
				<button onClick={handleClick} className="btn btn-dark">
					Generate Concept Poster
				</button>
			</div>

			{conceptPoster.length > 0 && (
				<img
					// className="card-img"
					style={{
						width: "100%",
						height: "auto",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						borderRadius: "0.75rem",
						boxShadow: "0px 0px 15px -10px #000000",
						padding: "0.75em",
						marginBottom: "0.75rem",
					}}
					src={!img_url ? loading : `data:image/png;base64,${img_url}`}
					alt="scenario img"
					onError={({ currentTarget }) => {
						currentTarget.onerror = null;
						currentTarget.src = NoImage;
					}}
				/>
			)}

			{conceptPoster.map((line, index) => {
				// Check for any of the keywords in the line
				const keywordFound = keywords.find((keyword) => line.includes(keyword));

				if (keywordFound) {
					const keywordIndex = line.indexOf(keywordFound);
					const beforeKeyword = line.substring(0, keywordIndex);
					const afterKeyword = line.substring(keywordIndex + keywordFound.length);

					return (
						<div key={index} style={{ display: "flex", gap: "1rem", alignItems: "center", margin: "0" }}>
							<div style={{ width: "100%", marginBottom: "0.5rem" }}>
								<p>
									{beforeKeyword}
									<strong>{keywordFound}</strong>
									{afterKeyword}
								</p>
							</div>
						</div>
					);
				} else {
					return (
						<div key={index} style={{ display: "flex", gap: "1rem", alignItems: "center", margin: "0" }}>
							<div style={{ width: "100%", marginBottom: "0.5rem" }}>
								<p>{line}</p>
							</div>
						</div>
					);
				}
			})}
		</div>
	);
}

import React, { useContext, useState, useEffect, useCallback } from "react";

const AppContext = React.createContext();

const AppContextProvider = ({ children }) => {
	const [isSidebarVisible, setIsSidebarVisible] = useState(true);

	const handleSidebar = () => {
		setIsSidebarVisible(!isSidebarVisible);
	};

	return <AppContext.Provider value={{ isSidebarVisible, handleSidebar }}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
	return useContext(AppContext);
};

export { AppContextProvider };

import React from "react";
import { Link } from "react-router-dom";

import ErrorImg from "../../assets/error.svg";
import "./error.css";

export default function Error({ error }) {
	console.log(error);

	return (
		<div className="container">
			<img src={ErrorImg} alt="error-img" className="error-img" />

			<p className="not-found-text">
				Aaaah! Something went wrong.
				<br />
				Brace yourself till we get the error fixed. You may also refresh
				the page or try again later.
			</p>

			<Link
				to={`/`}
				className="btn btn-dark"
				style={{
					marginTop: "0.5rem",
					display: "inline-block",
				}}>
				Take me back to the homepage
			</Link>
		</div>
	);
}

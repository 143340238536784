import { useState } from "react";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSave } from "@fortawesome/free-solid-svg-icons";
import "./controls.css";

const EditText = ({ id, content, isEditing, onEdit, onSave }) => {
	const [text, setText] = useState(content);

	const handleSaveClick = () => {
		onSave(content);
	};

	return (
		<div>
			{isEditing ? (
				<button onClick={handleSaveClick} className="control-btn">
					<FontAwesomeIcon icon={faSave} style={{ outline: "none" }} data-tooltip-id={`${id}-save-tooltip`} data-tooltip-content="Save" />
					<Tooltip id={`${id}-save-tooltip`} />
				</button>
			) : (
				<button onClick={onEdit} className="control-btn">
					<FontAwesomeIcon icon={faPen} style={{ outline: "none" }} data-tooltip-id={`${id}-edit-tooltip`} data-tooltip-content="Edit" />
					<Tooltip id={`${id}-edit-tooltip`} />
				</button>
			)}
		</div>
	);
};

export default EditText;

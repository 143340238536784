export default function SimpleModal({ title, messages, isFeedbackAllowed, closeHandler }) {
	return (
		<div className="modal">
			<div className="modal-content">
				<span className="close" onClick={closeHandler}>
					&times;
				</span>

				{title && <h2 className="modal-title text-dark">{title}</h2>}

				{messages &&
					messages.map((message, index) => (
						<p key={index} className="message" style={{ marginBottom: "1rem" }}>
							{message}
						</p>
					))}

				{isFeedbackAllowed && (
					<p className="message">
						<span className="text-dark" style={{ fontWeight: "bold" }}>
							Do you have questions or concerns regarding this service?
						</span>{" "}
						Feel free to reach out to us using the feedback button, and we'll be happy to address any inquiries you may have.
					</p>
				)}
			</div>
		</div>
	);
}

import React, { useState } from "react";

import "./modal.css";

export default function Modal({ onClick, modalContent }) {
	const [copied, setCopied] = useState(false);

	const onCopy = () => {
		const textArea = document.createElement("textarea");
		textArea.value = `${window.location.origin}/share/${modalContent.id}`;

		document.body.appendChild(textArea);
		textArea.select();

		document.execCommand("copy");
		document.body.removeChild(textArea);

		setCopied(true);

		setTimeout(() => {
			setCopied(false);
		}, 3000);
	};
	return (
		<div className="modal">
			<div className="modal-content">
				<span className="close" onClick={onClick}>
					&times;
				</span>
				<h2 className="modal-title text-dark">Share link to your Design Flight</h2>
				<p className="message">
					Easily share your amazing Design Flight by clicking the
					<span className="text-dark">
						<b> "Copy Link"</b>
					</span>{" "}
					button. Send it to friends, collaborators, or anyone you want to impress! It's that simple – sharing creativity has never been
					more convenient. Try it out now!
				</p>

				<button className="btn btn-dark" onClick={onCopy}>
					Copy Link
				</button>

				{copied && <span className="copy-feedback">Copied to clipboard!</span>}
			</div>
		</div>
	);
}

import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";

import { useAuthContext } from "../../contexts/AuthContext";

import Logo from "../../assets/logo.png";
import "./navigation.css";

export default function Navigation() {
	const { user, logoutUser } = useAuthContext();

	return (
		<div className="nav">
			<div className="nav__logo">
				<Link className="nav__link" to="/">
					<img src={Logo} alt="logo" className="nav__img" />
				</Link>

				<Link className="nav__link" to="/">
					Innovation Flightdeck
				</Link>
			</div>

			{user ? (
				<div className="nav__controls nav__controls--authenticated">
					<div className="nav__user">
						<p className="nav__user-email">{user.email}</p>

						<Link className="nav__link" to="/auth/profile">
							<FontAwesomeIcon icon={faCircleUser} className="nav__user-icon" />
						</Link>
					</div>
					<Link className="nav__link" to="#" onClick={logoutUser}>
						Logout
					</Link>
				</div>
			) : (
				<div className="nav__controls nav__controls--unauthenticated">
					<Link className="nav__link" to="/auth/login">
						Login
					</Link>
					<Link className="nav__link" to="/auth/register">
						Register
					</Link>
				</div>
			)}
		</div>
	);
}

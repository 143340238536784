import { useFlightContext } from "../../../contexts/FlightContext";
import { TabSection } from "../../../components";

import "./design-challenge.css";

export default function DesignChallenge() {
	const { currentVersion } = useFlightContext();

	return (
		<div id="design-challenge" className="tab-container">
			<TabSection title="Summary" content={currentVersion.summary} />

			<TabSection title="Design Challenge" content={currentVersion.designChallenge} />
		</div>
	);
}

import React, { useState, useEffect } from "react";

import { Navigation } from "../../components";
import { Error } from "../../pages";
import Card from "./Card";
//import Error from "./Error";
import InputSection from "./InputSection";
import DesignChallengeSection from "./DesignChallengeSection";
import { askGPT, generateImage } from "../../services/flightService";
import { SCENARIO_TITLES, MAIN_TASK } from "../../assets/feedData";

import "./inspiration-cards.css";

function App() {
	const [userInput, setUserInput] = useState("");
	const [designChallengeAbstract, setDesignChallengeAbstract] = useState("");
	const [scenarios, setScenarios] = useState({});
	const [imageUrls, setImageUrls] = useState({});
	const [generateImages, setGenerateImages] = useState(false);

	const [showDesignChallengeContainer, setShowDesignChallengeContainer] = useState(false);
	const [showImageContainer, setShowImageContainer] = useState(false);

	const [isDesignChallengeResponseLoading, setIsDesignChallengeResponseLoading] = useState(false);
	const [isSolutionLoading, setIsSolutionLoading] = useState(false);
	const [error, setError] = useState(false);

	const handleDesignChallengeSubmit = async (e) => {
		e.preventDefault();

		if (userInput === "") return;

		setIsDesignChallengeResponseLoading(true);
		setShowDesignChallengeContainer(true);

		let response = null;
		try {
			response = await askGPT([], `${MAIN_TASK}: "${userInput}"`);

			if (response.length > 0) {
				setDesignChallengeAbstract(response);
				setIsDesignChallengeResponseLoading(false);
			}
		} catch (e) {
			setError(true);

			console.error(e);
		}
	};

	const handleTryAgain = (e) => {
		setDesignChallengeAbstract("");
		handleDesignChallengeSubmit(e);
	};

	useEffect(() => {
		async function generateScenarios() {
			if (!designChallengeAbstract || !generateImages) return;

			setIsSolutionLoading(true);
			setShowImageContainer(true);

			const scenarioPrompts = Object.values(SCENARIO_TITLES).map(
				(title) => `Give an example from "${title}" where the following question has been solved`
			);

			try {
				const scenarioResponses = await Promise.all(
					scenarioPrompts.map(async (prompt) => {
						let response = null;

						try {
							response = await askGPT([], `${prompt}: ${designChallengeAbstract}. Keep answer under 800 characters.`);
						} catch (e) {
							setError(true);

							console.error(e);
						}

						return response;
					})
				);

				const scenarioTexts = scenarioResponses.reduce((texts, response, index) => {
					const key = Object.keys(SCENARIO_TITLES)[index];
					texts[key] = response || "";
					return texts;
				}, {});
				setScenarios(scenarioTexts);

				const imageResponses = await Promise.all(
					Object.entries(scenarioTexts).map(async ([_, scenario]) => {
						console.log(scenario);
						let response = null;

						try {
							response = await generateImage(scenario);
						} catch (e) {
							setError(true);

							console.error(e);
						}
						return response;
					})
				);

				const scenarioImgUrls = imageResponses.reduce((urls, response, index) => {
					const key = Object.keys(SCENARIO_TITLES)[index];
					urls[key] = response || "";
					return urls;
				}, {});
				setImageUrls(scenarioImgUrls);

				setIsSolutionLoading(false);
				setGenerateImages(false);
			} catch (e) {
				setError(true);

				console.log(e);
			}
		}

		generateScenarios();
	}, [designChallengeAbstract, generateImages]);

	const handleGenerateImages = () => {
		setGenerateImages(true);
	};

	if (error) {
		return <Error error={error} />;
	}

	return (
		<div id="inspiration-cards">
			<Navigation />

			<InputSection submitHandler={handleDesignChallengeSubmit} userInput={userInput} setUserInput={setUserInput} />
			{showDesignChallengeContainer && (
				<DesignChallengeSection
					isLoading={isDesignChallengeResponseLoading}
					abstract={designChallengeAbstract}
					generate={handleGenerateImages}
					retry={handleTryAgain}
				/>
			)}
			{showImageContainer && (
				<section id="images-section" className="inspiration-container card-list">
					{Object.entries(SCENARIO_TITLES).map(([key, title]) => (
						<Card key={key} title={title} img_url={imageUrls[key]} text={scenarios[key]} isLoading={isSolutionLoading} />
					))}
				</section>
			)}
		</div>
	);
}

export default App;

import "./input-item.css";

export default function InputItem({ id, icon, question, placeholder, value, onChange, disabled }) {
	return (
		<div className="input-item">
			<img src={icon} className="input-icon" alt="question icon" />

			<div>
				<label htmlFor={id}>{question}</label>
				<input
					className="input-field"
					id={id}
					name={id}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					autoComplete="off"
					required
					disabled={disabled}></input>
			</div>
		</div>
	);
}

import jsPDF from "jspdf";
import "jspdf-autotable";
import DOMPurify from "dompurify";

export const updateTabsByName = (prevTabs, tabNames, newEnabledValue) => {
	return prevTabs.map((tab) => {
		if (tabNames.includes(tab.title)) {
			return { ...tab, enabled: newEnabledValue };
		}
		return tab;
	});
};

export const checkCondition = (conditions) => {
	return conditions.every(Boolean);
};

export const createPDF = (data, img_url) => {
	const resizeBase64Img = (base64Str, maxWidth, maxHeight, callback) => {
		const img = new Image();
		img.src = base64Str;

		img.onload = () => {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");

			let width = img.width;
			let height = img.height;
			const aspectRatio = width / height;

			if (width > height) {
				if (width > maxWidth) {
					height = Math.round(height * (maxWidth / width));
					width = maxWidth;
				}
			} else {
				if (height > maxHeight) {
					width = Math.round(width * (maxHeight / height));
					height = maxHeight;
				}
			}

			canvas.width = width;
			canvas.height = height;
			ctx.drawImage(img, 0, 0, width, height);

			const resizedBase64 = canvas.toDataURL("image/png");
			callback(resizedBase64);
		};
	};

	// Resize the image before creating the PDF
	resizeBase64Img(img_url, 500, 500, (resizedBase64) => {
		const doc = new jsPDF();

		try {
			const pageWidth = doc.internal.pageSize.getWidth();
			const pageHeight = doc.internal.pageSize.getHeight();

			const imgWidth = 150;
			const imgHeight = 150;

			const xPos = (pageWidth - imgWidth) / 2;
			const yPos = 14;

			doc.addImage(resizedBase64, "PNG", xPos, yPos, imgWidth, imgHeight);
		} catch (e) {
			console.error("Error adding image to PDF: ", e);
		}

		doc.autoTable({
			startY: 170,
			theme: "striped",
			styles: { cellPadding: 4, lineWidth: 0.25, lineColor: "black" },
			columnStyles: {
				0: { cellWidth: 40, fontStyle: "bold", fillColor: "#ddc8d0" },
			},
			body: data,
		});

		// Generate the PDF Blob
		const pdfBlob = doc.output("blob");

		// Create an object URL for the PDF Blob
		const pdfUrl = URL.createObjectURL(pdfBlob);

		// Trigger a download of the PDF
		const link = document.createElement("a");
		link.href = pdfUrl;
		link.download = "concept-poster.pdf"; // Specify the filename
		document.body.appendChild(link); // Append the link to the body (required for Firefox)
		link.click(); // Programmatically click the link to start the download
		document.body.removeChild(link); // Remove the link after download is triggered

		// Revoke the object URL after download
		URL.revokeObjectURL(pdfUrl);
	});
};

// Helper to format a section of the PDF content
export const formatSection = (title, content) => ({
	title,
	content: `${content}`,
});

// Helper to format the personas section
export const formatPersonas = (personas) =>
	Object.keys(personas)
		.map((persona) => `${personas[persona].name}\nNeed: ${personas[persona].need}\nInsight: ${personas[persona].insight}\n`)
		.join("\n");

// Helper to format the user journey section
export const formatUserJourney = (selectedUserJourney, selectedPersonaName) =>
	`${selectedPersonaName}\n\n${Object.entries(selectedUserJourney)
		.map(
			([key, value], index) =>
				`Touchpoint ${index + 1}: ${value.title}\nThoughts: ${value.thoughts}\nActions: ${value.actions}\nSatisfaction Level: ${
					value.satisfactionLevel
				}\n`
		)
		.join("\n")}`;

export const decodeHtmlEntities = (str) => {
	const txt = document.createElement("textarea");
	txt.innerHTML = str;
	return txt.value;
};

export const formatContent = (content) => {
	return content.replace(/\n/g, "<br>");
};

export const formatPDFContent = (content) => {
	return content.replace(/<br\s*\/?>/gi, "\n").replace(/<[^>]*>/g, "");
};

export const cleanUpHtml = (html) => {
	return html.replace(/(<p>)+/g, "<p>").replace(/<\/p>(<\/p>)+/g, "</p>");
};

export const createMarkup = (html) => {
	return { __html: DOMPurify.sanitize(html) };
};

// Generate a random ID
export const randomId = () => {
	return Math.random().toString(36).substring(2, 15);
};

import React, { useContext, useState, useEffect } from "react";
import { logout, getCurrentUser } from "../services/authService";
const AppContext = React.createContext();

const AuthContextProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const saveUser = (user) => {
		setUser(user);
	};

	const removeUser = () => {
		setUser(null);
	};

	const fetchUser = async () => {
		try {
			const response = await getCurrentUser();

			if (response.status === 200) {
				const user = await response.json();
				saveUser(user.user);
			} else {
				const message = await response.json();
				console.log(message);
			}
		} catch (error) {
			removeUser();
		}
		setIsLoading(false);
	};

	const logoutUser = async () => {
		try {
			await logout();
			removeUser();
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchUser();
	}, []);

	return (
		<AppContext.Provider
			value={{
				isLoading,
				user,
				saveUser,
				logoutUser,
			}}>
			{children}
		</AppContext.Provider>
	);
};

export const useAuthContext = () => {
	return useContext(AppContext);
};

export { AuthContextProvider };

import React from "react";

export default function DesignChallengeSection({ isLoading, abstract, generate, retry }) {
	return (
		<section id="design-challenge-section" className="inspiration-container">
			<h2>Your abstracted Design Challenge:</h2>
			{isLoading ? <p>Generating abstract...</p> : <p>{abstract}</p>}

			{abstract && (
				<div className="btn-group">
					<button className="btn btn-dark" onClick={generate}>
						Generate Inspirations
					</button>
					<button className="btn btn-dark" onClick={retry}>
						Try Again
					</button>
				</div>
			)}
		</section>
	);
}
